<template>
    <div v-if="item">
        <v-row justify="center" >
            <div  style="width: 100%; max-width: 1115px;">
                <v-container>
                    <v-row>
                        <v-col class="d-none d-md-flex" cols="12" md="3" lg="2" >
                            <Nav :showRestrictArea="showRestrictArea"
                                :author="item.fullName" 
                                :avatar="item.avatar" 
                                :selectedItem="selectedItem" 
                                :showProject="showProject"
                                @select="onSelect"/>
            
                        </v-col>
                        <v-col cols="12" md="9" lg="10">
                            <div class="mt-3">
                                <Banner 
                                    v-if="portfolio.cover" 
                                        :src="portfolio.cover"
                                        :clipped="false" height="300px"/>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>

                <v-container  class="d-block d-md-none">
                    <Nav2 :showRestrictArea="showRestrictArea"
                        :author="item.fullName" 
                        :avatar="item.avatar" 
                        :selectedItem="selectedItem" 
                        :showProject="showProject"
                        @select="onSelect"/>
                </v-container>

                <v-container class="mt-10">
                    <v-row >
                        <v-col cols="12" md="3" lg="2" >
                            <div class="body pa-0 ma-0 py-2 px-4 pa-md-4 " style="max-width: 240px">
                                <p class=" pa-0 ma-0 mt-md-5 text-uppercase  text-primary">
                                    <span class="h3">Bio</span> <span style="font-size: 12px">Profissional</span></p>
                            </div>
                        </v-col>
                        <v-col cols="12"  md="9" lg="10">
                            <div v-html="portfolio.bio" style="font-size: 17px"></div>
                        </v-col>
                    </v-row>
                </v-container>

                <v-container class="mt-10" v-if="portfolio.rel">
                    <v-row  no-gutters="" justify-md="end">
                         <!--v-col class="d-none d-md-flex" cols="12" md="3" lg="2" >
                            </v-col-->
                        <v-col cols="12"  md="9" lg="10">
                            <v-sheet class="mx-auto secondary" style="max-height: 600px; max-width: 1115px;">
                                <div class="text-center" >
                                    <VideoPlayer :src="portfolioRel"/>
                                    <!--video width="100%" controls>
                                        <source :src="portfolio.reel" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video--> 
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-container>
    
                <v-container class="mt-10">
                    <v-row >
                        <v-col cols="12" md="3" lg="2" >
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <div class="body pa-0 ma-0 py-2 px-4 pa-md-4 " style="max-width: 240px">
                                        <p  class=" pa-0 ma-0 mt-md-5 text-uppercase  text-primary">
                                            <span class="h3">Bio</span> <span style="font-size: 12px">Cinematográfica</span></p>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <blockquote class="mt-4"  style="font-size: 14px">{{ portfolio.quote }}</blockquote>
                                </v-col>
                            </v-row>
                            
                        </v-col>
                        <v-col cols="12"  md="9" lg="10">
                            <v-row>
                                <v-col>
                                    <div v-html="portfolio.cinematographicBio"  style="font-size: 17px"></div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
                
                <ProjectInfo v-if="item.projects && item.projects.length > 0" :projects="item.projects" />
            </div>
        </v-row>
    </div>
</template>
<script>
import Banner from '../Banner';
import Nav from './Nav';
import Nav2 from './Nav2';
import ProjectInfo from './ProjectInfo';
import VideoPlayer from '../VideoPlayer';
export default {
    props: ['item',  'showRestrictArea','showProject', 'selectedItem'],
    computed: {
        portfolio() {
            return this.item.portfolio || {};
        },
        portfolioRel() {
            const rel = this.portfolio.rel;
            try {
                const url = new URL(rel);
                return url.href;
            } catch (_) {
                return '//'+rel
            }
        }
    },
    components: {
        Banner,
        Nav,
        Nav2,
        ProjectInfo,
        VideoPlayer,
    },
    methods: {
        onSelect(val) {
            this.$emit('select', val);
        }
    }
}
</script>