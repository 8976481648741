<template>
    <v-container v-if="items">
        <v-row>
            
            <v-col cols="12" md="9" lg="10" order-md="2">
                <transition name="fadeInRight">
                    <v-sheet class="mx-auto secondary" elevation="8">
                        <VideoPlayer 
                            v-if="item.externalLink" 
                            :key="item.id" :src="item.externalLink" :poster="item.src"/>
                        <template v-else >
                            <div style="position: relative; width: 100%">
                                <v-col>
                                <Banner 
                                    :src="item.src"  :clipped="false" height="350px"/>
                                    </v-col>
                                
                               <div style="min-height: 50px; width:100%; position: absolute; left: 0; bottom: 0px; background-color: rgba(255, 204, 0, .8)">
                                    <p class="black--text my-3 mx-2">{{item.title}}</p>
                               </div>
                            </div>
                        </template>
                    </v-sheet>
                </transition>
            </v-col>
            <v-col class="d-none d-md-flex d-lg-flex d-xl-flex" cols="12" md="3" lg="2"  order-md="1">
                
                <div 
                    style="max-height: 350px; max-width: 160px; width: 100%;"
                    class="overflow-y-auto ">
                    <template v-for="(item, i) in items" >
                        <div :key="item.id" ><div 
                            cols="12" class="pt-3 pb-3" 
                            :class="(active === null && i===0) || (active !== null && item.id === active.id) ? 'bordered' : ''" 
                            style="display: inline-block; width:120px;">
                            <v-sheet class="mx-auto secondary" width="115px" elevation="8">
                                <a @click="selectItem(item)">
                                    <Banner width="115px"
                                        :src="item.src" :clipped="false" height="100px"/>
                                </a>
                            </v-sheet>
                            
                        </div>
                        <br/>
                        </div>
                    </template>
                </div>
            </v-col>
            <v-col cols="12" class="d-md-none ">
                <v-sheet
                    class="mx-auto card-secondary"
                    elevation="8"
                    max-width="800">
                    <v-slide-group
                        v-model="model"
                        class="pl-4" center-active
                        show-arrows >

                        <v-slide-item
                            v-for="(item) in items" :key="item.id" 
                            v-slot:default="{ active, toggle }">
                            <v-card
                                class="ma-4"
                                height="100"
                                width="100">
                                <v-row
                                    :class="(active) ? 'bordered' : ''" 
                                    align="center"
                                    justify="center">
                                    <v-col>
                                        <a @click="selectItem(item, toggle)">
                                            <Banner width="100px"
                                                :src="item.src" :clipped="false" height="115px"/>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" class="ml-md-10 pl-md-10" md="8">
                <p class="h2 my-10 text-uppercase text-primary">{{ item.title }}</p>
                <p>{{ item.paisProducaoDescricao }} {{item.paisProducaoDescricao && item.dataProducaoFmt ? ' • ' : null}} {{ item.dataProducaoFmt }}</p>
                
                <div>
                    <p class="h4 my-10 text-uppercase text-primary">Bio</p>
                    <div class="ml-10" v-html="item.bio"></div>
                </div>
                <div>
                    <p class="h4 my-10 text-uppercase text-primary">Créditos</p>
                    <div class="ml-10"> 
                        <v-row>
                            <v-col v-for="credit in item.credits" :key="credit.id"
                                cols="12" md="4">
                                <span><font-awesome-icon icon="circle" size="xs" class="text-primary"/>  <span> {{ credit.name }}</span></span>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div>
                    <p class="h4 my-10 text-uppercase text-primary">Prémios/Nomeações</p>
                    <div class="ml-10">
                        <v-row>
                            <v-col v-for="award in item.awards" :key="award.id"
                                cols="12" md="4">
                                <span><font-awesome-icon icon="award" size="2x" class="text-primary"/>  <span> {{ award.name }}</span></span>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Banner from '../Banner';
import VideoPlayer from '../VideoPlayer';
export default {
    props: ['items'],
    data: () => ({
        active: null,
        model: null,
    }),
    computed: {
        item() {
            if (this.active == null && this.items.length > 0) {
                return this.items[0];
            }
            return this.items.find((i) => this.active.id === i.id) || {};
        }
    },
    methods: {
        selectItem(item, cb) {
            this.active = item;
            if (cb instanceof Function) {
                cb();
            }
        }
    },
    components: {
        Banner,
        VideoPlayer,
    }
}
</script>