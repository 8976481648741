<template>
    <div v-if="items" >
        <v-container>
            <v-row no-gutters >
                <v-col cols="12" md="9" style="width: 680px; max-width: 680px; height: 480px;">
                    <PostContainer headless clipped>
                        <v-row >
                            <v-col v-for="contact in items" 
                                :key="contact.name" 
                                cols="12" md="6">
                                <div class="border-left">
                                    <p class="h4 text-uppercase">{{ contact.name }}</p>
                                    <p class="text-uppercase">{{ contact.value || '- - -  - - -'}}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </PostContainer>
                </v-col>
            </v-row>
            <div class="d-block d-md-none">
                <br /><br /><br />
                <br /><br /><br />
                <br /><br /><br />
            </div>
        </v-container>
    </div>
</template>

<script>
import PostContainer from '../PostContainer';
export default {
    props: ['items'],
    components: {
        PostContainer,
    }
}
</script>