<template>
    <v-container v-if="item" style="max-width: 1600px">
        <v-row>
            
            <v-col v-if="!isBioActive" cols="12" md="2" class="px-0 mr-md-4 pb-0" style="max-width: 200px;">
                <Nav :showRestrictArea="showRestrictArea"
                    :author="item.fullName" 
                    :avatar="item.avatar" 
                    :selectedItem="selectedItem" 
                    :showProject="showProject"
                    @select="onSelect"/>
            </v-col>
            <v-col v-if="isContactActive || isBioActive" class="pt-md-0">
                
                <div v-if="isContactActive" class="ml-md-10 mt-6">
                    <br><br>
                    <p class="h3 text-uppercase text-primary pl-3" style="font-size: 62.5px"> {{ item.fullName }}</p>
                    <Contact :items="item.contacts"/>
                </div>
                
                <div v-else-if="isBioActive">
                    <br>
                    <Bio :item="item" 
                        @select="onSelect" 
                        :showProject="showProject" 
                        :selectedItem="selectedItem" 
                        :showRestrictArea="showRestrictArea"/>
                </div>

                <!--v-container v-else-if="isGeoActive">
                    <br>
                    <GoogleMap apiKey="" :config="{ zoom: 5, center: {lat: 14.7523932, lng: -23.1181571} }"/>
                </v-container-->
                
            </v-col>
            <v-col v-else cols="12" md="9" class="pt-md-0">
                <div v-if="showProject && isGeoActive">
                    <Projects />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Nav from './Nav';
import Bio from './Bio';
import Contact from './Contact';
import Projects from '../../pages/dashboard/control-panel/Projects';
//import Projects from './ProjectListV2';
// import GoogleMap from '../google/Map';

export default {
    props: ['item', 'showRestrictArea', 'showProject'],
    data: () => ({
        selectedItem: 1,
    }),
    computed: {
        isContactActive() {
            return this.selectedItem === 0;
        },
        isBioActive() {
            return this.selectedItem === 1 || (this.selectedItem!=0&&!this.selectedItem);
        },
        isGeoActive() {
            return this.selectedItem === 2;
        },
    },
    methods: {
        onSelect(val) {
            this.selectedItem = val;
        }
    },
    components: {
        Nav,
        Bio,
        Contact,
        Projects
    }
}
</script>