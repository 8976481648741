<template>
    <div v-if="projects" class="mt-10">
        <ProjectCarousel :items="projects" />
    </div>
</template>
<script>
import ProjectCarousel from './ProjectCarousel';
export default {
    props: ['projects'],
    components: {
        ProjectCarousel,
    }
}
</script>