<template>
    <div class="body pt-4" style="width: 195px; max-width: 195px;">
        <div class="text-center">
            <div><v-avatar size="72">
                <img v-if="avatar"  class="avatar-bordered light"
                    :src="avatar"
                     :title="author">
                <div  v-else> <font-awesome-icon icon="user-circle" class="text-primary" size="4x"/></div>
            </v-avatar></div>
            <div class="my-2">{{ author }}</div>
        </div>
        <v-divider class="my-1"></v-divider>
        <v-list dense class="module-background py-0">
            <v-list-item-group :value="selectedItem" mandatory class="mx-0">
                <template
                     v-for="(item, i) in items"
                     >
                <v-list-item v-if="item.text !== 'Projetos' || showProject" :key="i"
                   v-model="item.active" 
                    :class="item.active ? 'primary' : ''"
                    @click="onSelect(i)">
                <v-list-item-icon class="pb-0 mr-2">
                    <font-awesome-icon  :icon="item.icon"  :class="item.active ? 'black--text' : ''"/>
                </v-list-item-icon>
                <v-list-item-content class="mb-2">
                    <v-list-item-title v-text="item.text"  :class="item.active ? 'black--text' : ''"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>

        <div>
            <br><br>  <br><br>  <br><br>
        </div>
        <v-divider class="my-1"></v-divider>

        <v-toolbar-items style="height: 48px" v-if="user && showRestrictArea">
            <v-btn text @click="signOutUser" title="sair">
                <font-awesome-icon icon="sign-out-alt"/>
            </v-btn>

            <v-divider
                class="mx-4"
                inset
                vertical></v-divider>

            <v-btn text :to="dashboardRoute" title="Dashboard">
                <font-awesome-icon icon="cog"/>
            </v-btn>
        </v-toolbar-items>
    </div>
</template>
<script>
import { HOME, PROFILE_DASHBOARD } from '../../routes';

import { mapActions, mapMutations } from 'vuex';
export default {
    props: ['author', 'avatar', 'selectedItem', 'showRestrictArea', 'showProject'],
    data: () => ({
        items: [
            {
                icon: 'comment',
                text: 'Contacto',
                active: true,
            },
            {
                icon: 'film',
                text: 'Portfólio'
            },
            
            {
                icon: 'film',
                text: 'Projetos'
            }
            /* {
                icon: 'map-marker-alt',
                text: 'Geo'
            } */
        ],
        dashboardRoute: PROFILE_DASHBOARD,
    }),
    computed: {
        user() {
            if (this.$user.isAuthenticated) {
                return this.$user.info;
            }
            return null;
        }
    },
    methods: {
        onSelect(_new) {
            this.$emit('select', _new);
        },
        ...mapMutations('auth', ['authenticated']),
        ...mapActions('auth', ['signOut']),
        signOutUser() {
            this.signOut().then((success) => {
                if (success) {
                    this.$user.logout();
                    this.authenticated(false);
                    this.$router.push({ path: HOME });
                }
            });
        }
    }
}
</script>